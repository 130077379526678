.wrapperImg {
    display: flex;
    /* gap: 5px; */
}

/* .centerRadioButton{
    display: flex;
    align-items: center;
    justify-content: center;
} */
.d-flex svg {
    width: auto;
}
.d-flex img {
    object-fit: contain;
}
.wrapperImg img {
    object-fit: contain;
    margin-top: 3px;
    width: 20px;
}
/* .d-flex label {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 3px;
} */
.form-check label {
    margin-left: -5px;
}

.checkM {
    margin-right: 0.6rem !important;
}

/* ::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
}
::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom,#00BCFF,#007AFF);
}
::-webkit-scrollbar-track {
    background-color: #F1F1F1;
} */
