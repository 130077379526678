.cursor_pointer{
    cursor: pointer;
}

.bg_color{
    /* background-color: #27496D !important; */
    /* background-color: #212529 !important; */
    background-color: #ffc206 !important;
    color: #000 !important;
}
.bg_color_selected{
    background-color: var(--color-fondo) !important;
    /* background-color: #7290af !important; */
    color: #27496D !important;
}

