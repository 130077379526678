.movie-card {
    background: #dae1e7;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 12px 20px;
    margin: 8px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
}

.movie-card:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.movie-card__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.movie-card__title {
    margin: 0;
    font-size: 0.875rem;
    color: #2c3e50;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.2s ease;
}

.movie-card__title:hover {
    color: #3498db;
}

.movie-card__user {
    font-size: 0.75rem;
    color: #666;
    font-weight: 400;
    background: #fff;
    padding: 2px 8px;
    border-radius: 12px;
}

.status-badge {
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: capitalize;
    background: #e8f5e9;
    color: #2e7d32;
}

.movie-card__close {
    background: none;
    border: none;
    /* padding: 4px; */
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s ease;
}

.movie-card__close:hover {
    background: rgba(0, 0, 0, 0.1);
    color: #e74c3c;
}
