.container__infomovie {
    width: 100%;
    /* height: 100%; */
}

.infomovie__img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
}

.infomovie__img img {
    position: relative;
    width: 50%;
    height: 50%;
    object-fit: cover;
    border-radius: 10px;
}
