 .mi_label{
    display: flex !important;
    align-items: normal !important;
    justify-content: normal !important;
    gap: 0 !important;
    flex-direction: column;
    width: 70px;
    cursor: pointer;
  }
  
  .mi_label .mi_span{
    background: #ffc206;
    border-radius:10px;
    height:7px;
    margin: 7px 0;
    transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
  
  }
  
  
  .mi_span:nth-of-type(1){
    width:50%;
    
  }
  
  .mi_span:nth-of-type(2){
    width:100%;
  }
  
  
  .mi_span:nth-of-type(3){
    width:75%;
   
  }
  
  
  .mi_input[type="checkbox"]{
    display:none;
  }
  
  
  .mi_input[type="checkbox"]:checked ~ .mi_span:nth-of-type(1){
    transform-origin:bottom;
    transform:rotatez(45deg) translate(8px,0px)
  }
  
  
  .mi_input[type="checkbox"]:checked ~ .mi_span:nth-of-type(2){
    
    transform-origin:top;
    transform:rotatez(-45deg)
  }
  
  
  .mi_input[type="checkbox"]:checked ~ .mi_span:nth-of-type(3){
    
    transform-origin:bottom;
    width:50%;
    transform: translate(30px,-11px) rotatez(45deg);
  
  }
  