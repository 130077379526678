/* PALETA DE COLORES */
:root {
    /* --color-fondo: #DADBBD; */
    --color-fondo: #dae1e7;
    --colo-primary: #151d3b;
    --color-segundary: #d82148;
    --color-rojo-segundario: #ff577a;
    --color-info: #6ebf8b;
    -color-background-body: #f8f9fa;
    --color-yellow: #ffc107;
}
.color_fondo {
    background-color: var(--color-fondo);
}

/***** RESET ****/

html {
    box-sizing: border-box;
    font-family: var(--font);
    font-size: 16px;
}
/* Estilo para el track (la pista) del scroll */
::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* color de fondo */
}

/* Estilo para el thumb (la barra deslizante) del scroll */
::-webkit-scrollbar-thumb {
    background-color: var(--color-rojo-segundario); /* color del thumb */
    border-radius: 10px; /* bordes redondeados */
}

/* Estilo para el botón de flecha en el scroll */

/* Estilo para la esquina del scroll */
::-webkit-scrollbar-corner {
    background-color: #999; /* color de la esquina */
}

/* Estilo para el scroll en general */
::-webkit-scrollbar {
    width: 10px; /* ancho del scroll */
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

body {
    margin: 0;
    overflow-x: hidden;
    /* background-color: #F8F9FA !important; */
    position: relative;
    height: auto;
    min-height: 100vh !important;
    /* font-family: 'Poppins, sans-serif'; */
}

h1 {
    margin: 0;
    font-size: 2rem;

    /* font-family: 'Poppins, sans-serif'; */
}

h2 {
    margin: 0;
    font-size: 1.5rem;
    /* font-family: 'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif; */
}

h3 {
    margin: 0;
    font-size: 1.25rem;
}

h4 {
    margin: 0;
    font-size: 1rem;
}
h5 {
    margin: 0;
    font-size: 0.85rem;
}

h6 {
    margin: 0;
    font-size: 0.7rem;
}

p {
    line-height: 1.6;
}

a {
    color: var(--color-main);
    transition: all 0.5s ease-out;
}

/* Evitamos problemas con los SVG */
svg {
    width: 100%;
    display: block;
    fill: currentColor;
}

img,
picture,
video,
iframe,
figure {
    max-width: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
    object-position: center center;
}

.btn-flat {
    background-color: purple;
    color: white;
}

.btn-xxl {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
}
/* li {
    list-style-type: none;
} */

ul {
    list-style: none; /* Quitamos los marcadores */
    padding: 0 !important; /* Quitamos el padding por defecto de la lista */
    /* margin-left: 10px !important; Separamos la lista de la izquierda */
}

.card-title {
    font-size: 16px !important;
}
label {
    font-size: 14px;
    /* font-family: 'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif; */
}

.error {
    border: 2px solid #ff8c8c !important;
    background-color: #ffd5d5 !important;
}

.error-message {
    color: #e60909;
    font-size: 0.8em;
}

/* #___gcse_0{
    width: 40%;
    margin: 80px auto;
} */

/* @media (min-width: 576px)
{.modal-dialog {
    max-width: 900px !important;
}} */

.form-check-input:checked {
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
}

/* .btn-primary {
    color: #fff;
    background-color: #6610f2 !important;
    border-color: #6610f2 !important;
} */

.bg-vino {
    /* background-color: #260505 !important; */
    background-color: #000000 !important;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}
.btn-primary {
    color: #fff;
    /* background-color: #212529 !important;
    border-color: #212529 !important; */
    background-color: #260505 !important;
    border-color: #260505 !important;
}
.btn-primary:hover {
    color: #ffc107;
}

.btn-warning {
    color: #fff;
    background-color: #ffc206 !important;
    border-color: #ffc206 !important;
}
.css-1shslvk-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled
    .btn-primary {
    color: rgb(255 194 6 / 46%);
}

.form-select:focus {
    border-color: #ffc206 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(253 165 13 / 25%) !important;
}
.form-control:focus {
    border-color: #ffc206 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(253 165 13 / 25%) !important;
}

/* select.decorated option:hover {
    box-shadow: 0 0 10px 100px #ffc206 inset !important;
} */
/* select > option:focus { 
    box-shadow: 0 0 10px 100px #ffc206 inset !important;
    background-color: yellow !important;
} */

/* select.form-select > option:hover{
    text-decoration: none;
  color: #ffffff;
  background-color: #f3969a !important;
} */

/* .nav-tabs .nav-link.active {
    color: #212529;
    background-color: #F8F9FA !important;
} */
.nav-link {
    color: #212529 !important;
}

#paste .nav-tabs .nav-link.active {
    background-color: #ebebeb !important;
    border-color: #dee2e6 #dee2e6 #ebebeb !important;
}
#colecciones .nav-tabs .nav-link.active {
    background-color: var(--color-fondo) !important;
    border-color: #dee2e6 #dee2e6 var(--color-fondo) !important;
}

.Image {
    background-image: url('/src/assets/images/wallpaper.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
}

.header_login {
    margin-top: -5px;
}

.nav-tabs .nav-link.active {
    -webkit-box-shadow: 0px -2px 1px 1px rgba(255, 194, 6, 1);
    -moz-box-shadow: 0px -2px 1px 1px rgba(255, 194, 6, 1);
    box-shadow: 0px -2px 1px 1px rgba(255, 194, 6, 1);
}

.toast-message {
    background: darkblue;
    color: #fff;
    font-size: 20px;
    width: 34vw;
    padding: 30px 20px;
}

.background-t {
    background-color: #ff0000 !important;
    color: white !important;
}

#justify-tab-example-tab-peliculas_filmaffinity img {
    /* background-color: #447cad !important; */
    height: 35px;
}

/* dropdown search AsyncTypeahead */

.custom-dropdown .rbt-menu {
    max-height: 400px !important;
}
