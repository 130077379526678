.warning {
    background-color: #ffdcdc;
    border: 1px solid #ff6b6b;
    padding: 5px;

    /* display: inline-block; */
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 5px;
}

.warning div {
    color: #ff6b6b;
    font-size: 20px;
    margin-right: 5px;
    margin-left: 5px;
    animation: blink 0.5s ease-in-out infinite;
}

.warning span {
    color: #333;
    font-size: 15px;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
