.pro-sidebar-inner {
    /* background: #260505 ! important; */
    background: #000000 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    margin-right: 10px;
    font-size: 14px;
    width: 50px;
    min-width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: inline-block;
}

.pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper {
    background-color: #000000;
}
