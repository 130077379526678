.container__step1{
    /* background-color: var(--color-fondo); */
    background-color: #DAE1E7;
    /* height: 100%; */
    /* padding: 0 0 20px 20px; */

}
.form__items{
    display: flex;
    justify-content: center;
}