.custom-dropdown .rbt-close {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.custom-dropdown .rbt-input-main {
    padding-left: 28px !important;
}
